import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import { useQuery } from "../../hook/useQuery";

const FormAddUser = (props) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [tempName, setTempname] = useState("");
  const [type, setType] = useState({
    name: "VIP",
    value: "VIP",
  });
  const listType = [
    {
      name: "VIP",
      value: "VIP",
    },

    // {
    //   name: "General",
    //   value: "GENERAL",
    // },
    // {
    //   name: "General & VIP",
    //   value: "GENERAL,VIP",
    // },
  ];

  const [msg, setMsg] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const getBaseUrl = () => {
    if (window?.location?.origin) {
      var apiUrl = `${CONFIG.URL_ROOT}/checkin-mobile?id_gate=${query.get(
        "id_gate"
      )}&gate=${query.get("gate")}`;
      return apiUrl;
    } else {
      return "";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 1500);
  }, [msg]);

  const onSubmit = (data) => {
    if (type === null) {
      setMsg({
        success: false,
        msg: "Please choose ticket type",
      });
      return;
    }

    if (loading) return;

    setTempname(data.name);
    setLoading(true);
    const payload = {
      username: data.name,
      gate_access: "VIP",
      authorized_by: data.authorized,
    };

    console.log(payload);

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/user/add", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            reset();
            setMsg({
              success: true,
              msg: "Success Add User",
            });

            setTimeout(() => {
              window.location.href = getBaseUrl();
              setType(null);
            }, 1500);
          } else {
            setMsg({
              success: false,
              msg: "Failed Add User",
            });
          }
        })
        .catch(function (error) {
          setLoading(false);
          setMsg({
            success: false,
            msg: "Failed Add User",
          });
        });
    } catch (error) {
      setLoading(false);
      setMsg({
        success: false,
        msg: "Failed Add User",
      });
    }
  };

  const validateRFID = (value) => {
    if (value.length === 10) {
      return true;
    } else {
      return false;
    }
  };

  const resetForm = () => {
    setValue("rfid", "");
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-1/2"
      >
        <div className="text-center font-bold mb-5">
          <div className="text-2xl">ADD USER</div>
        </div>

        {msg !== null && msg?.success && (
          <>
            {/* <div className="text-center font-bold mb-8">
              <div className="text-2xl mt-5">Hi, {tempName}</div>
            </div> */}

            <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
              CHECKIN SUCCESS
            </div>
          </>
        )}

        {msg !== null && !msg?.success && (
          <div className="text-center font-bold text-white bg-red-500 py-2 rounded">
            {msg?.msg}
          </div>
        )}

        {(msg === null || !msg?.success) && (
          <>
            <div className="flex flex-col">
              <label className="text-black w-full font-bold">Username:</label>

              <input
                autoFocus
                className="border-2 px-2 h-[38px] rounded"
                {...register("name", { required: true })}
              />

              {errors.name && (
                <span className="text-red-500 text-sm">
                  This field is required
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <label className="text-black w-full font-bold">
                Authorized By:
              </label>

              <input
                className="border-2 px-2 h-[38px] rounded"
                {...register("authorized", { required: false })}
              />

              {errors.name && (
                <span className="text-red-500 text-sm">
                  This field is required
                </span>
              )}
            </div>

            {/* access privilege */}
            <div className="flex flex-col ">
              <div className="flex justify-between mb-1">
                <label className="text-black w-full font-bold uppercase">
                  Status:
                </label>
              </div>

              <div className="grid md:grid-cols-3 gap-3 mt-2">
                {listType?.map((v, key) => {
                  return (
                    <button
                      key={key}
                      type="button"
                      onClick={() => setType(v)}
                      className={`${
                        type?.name === v?.name ? "bg-blue-500" : "bg-gray-500"
                      } uppercase  w-full px-5 py-2 rounded-lg text-white font-bold text-center`}
                    >
                      {v?.name}
                    </button>
                  );
                })}
              </div>
            </div>

            <br />

            {/* SCAN RFID */}
            {/* <div className="flex flex-col">
              <div className="flex justify-between mb-1">
                <label className="text-black w-full font-bold">Scan RFID</label>
                <button
                  type="button"
                  onClick={() => resetForm()}
                  className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
                >
                  Clear
                </button>
              </div>

              <input
                autoComplete="off"
                role="presentation"
                className="border-2 px-2 h-[38px] rounded"
                {...register("rfid", {
                  required: true,
                  validate: validateRFID,
                })}
              />
            </div>
            {errors.rfid && (
              <span className="text-red-500 text-sm">
                RFID must consist of 10 characters
              </span>
            )} */}

            <div className="flex md:flex-row space-x-5 w-full">
              <a
                href={`./checkin-mobile?id_gate=${query.get(
                  "id_gate"
                )}&gate=${query.get("gate")}`}
                className={` md:min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
              >
                Cancel
              </a>
              <button
                type="submit"
                className={`md:min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default FormAddUser;
